import React, { useState, useContext } from "react";
import axios from "axios";
import contactPicture from "../../../../pictures/contactPicture.png"
import { MessageContext } from "../../../../ReactRouterSetup";
import "../../Main.css"

function ContactPartialView(props) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    projectAddress: "",
    projectDescription: ""
  });

  const { setMessage } = useContext(MessageContext);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFromData) => {
      return { ...prevFromData, [name]: value };
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const body = {
      Sender: formData.email,
      Subject: "Job offer",
      Content: `Phone number: ${formData.phoneNumber}; Name: ${formData.name}; Project address: ${formData.projectAddress}; Project description: ${formData.projectDescription}`,
    };

    await axios.post("https://majppgye6f.execute-api.eu-central-1.amazonaws.com/GabiDesign/GabiDesignSendEmail", body)
      .then(() => {
        setMessage("Submission was successful. \nThank you for contacting me.")
      })
      .catch((error) => {
        setMessage("Something went wrong.\nContact me directly.\nPhone number: +37069175539\nE-mail: gabi.interjerodizainas@gmail.com.");
      });
  };

  return (
    <div className="container-fluid d-flex flex-row p-0" style={{ background: "#F5F2EF" }}>
      {!props.isMobile && <div className="d-flex justify-content-center">
        <img className="img-fluid flex-item" src={contactPicture} style={{ width: '70vw' }} />
      </div>}
      <div className="col d-flex justify-content-start flex-column mt-4 mx-5" style={{ marginRight: "2%" }} >
        <div style={{ textAlign: (props.isMobile ? "center" : "start") }}>
          <h3 className="mb-4 contact-title-font mt-5" style={{ fontWeight: "bold" }}>GET IN TOUCH</h3>
          <h5 className="mb-4 contact-font">Gabija | Interior Designer</h5>
          <h5 className="contact-font">E-mail: gabi.interjerodizainas@gmail.com</h5>
        </div>
        <h5 className="inquiry-form-title mt-5" style={{ fontWeight: "bold" }}>INQUIRY FORM</h5>
        <form onSubmit={handleSubmit}>
          <div className="d-flex flex-column justify-content-between my-3 form-group">
            <input
              className="form-control contact-input contact-font px-0"
              maxLength={40}
              placeholder="NAME"
              name="name"
              onChange={handleChange}
              required
            />
          </div>
          <div className="d-flex flex-column justify-content-between my-3 form-group">
            <input
              type="email"
              className="form-control contact-input contact-font px-0"
              maxLength={320}
              placeholder="EMAIL"
              name="email"
              onChange={handleChange}
              required
            />
          </div>
          <div className="d-flex flex-column justify-content-between my-3 form-group">
            <input
              className="form-control contact-input contact-font px-0"
              maxLength={20}
              placeholder="PHONE NUMBER"
              name="phoneNumber"
              onChange={handleChange}
              required
            />
          </div>
          <div className="d-flex flex-column justify-content-between my-3 form-group">
            <input
              className="form-control contact-input contact-font px-0"
              maxLength={80}
              placeholder="PROJECT ADDRESS"
              name="projectAddress"
              onChange={handleChange}
              required
            />
          </div>
          <div className="d-flex flex-column justify-content-between my-3 form-group">
            <textarea
              className="form-control contact-input contact-font px-0"
              onChange={handleChange}
              maxLength={320}
              placeholder="PROJECT DESCRIPTION"
              name="projectDescription"
              required>
            </textarea>
          </div>
          <div className="d-flex justify-content-end">
            <button type="submit" className="submit-button p-0 my-3 contact-font" style={{ fontWeight: "bold" }}>SUBMIT</button>
          </div>
        </form >
      </div>
    </div>
  );
}

export default ContactPartialView;


